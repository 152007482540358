.loginPageContainer {
  background-color: var(--color-primary);
  font-family: Arial;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  height: 80vh;
}

.formContainer > h1 {
  font-size: 24px;
  color: white;
  font-weight: bold;
}

.formContainer > p {
  font-size: 1rem;
  color: white;
}

.formContainer > input {
  padding: 8px;
  text-indent: 8px;
  border: none;
  border-radius: 16px;
  width: 60%;
}

.formContainer > input:focus {
  outline: none;
}

.formContainer > span {
  width: 60%;
  display: flex;
  justify-content: space-between;
}

.formContainer > span > input {
  padding: 8px;
  text-indent: 8px;
  border: none;
  border-radius: 16px;
  width: 48%;
}

/* .formContainer > input::placeholder,
.formContainer > span > input::placeholder {
  color: var(--color-primary);
  font-weight: 500;
} */

.formButton {
  padding: 12px;
  border: none;
  border-radius: 16px;
  background-color: var(--color-primary);
  color: var(--color-tertiary);
  border: 1px solid var(--color-tertiary);
}

.loginLink {
  color: rgb(196, 196, 196);
  margin-left: 8px;
}

.loginAlert {
  width: 60%;
}

.loginButton {
  background-color: white !important;
  color: var(--color-primary) !important;
  width: 20% !important;
}

.mobileMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: var(--color-primary);
  color: white;
}
