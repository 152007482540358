@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

body {
  font-family: "Open Sans", sans-serif !important;
  height: 100%;
  overflow: scroll;
}

:root {
  --color-primary: #1D3554; /* Neutral dark grey */
  --color-secondary: #8e8e8e; /* Lighter grey */
  --color-tertiary: #b8b8b8; /* Even lighter grey */
  --color-red: #b53b3b;
  --color-green: #2b864f;
  --color-background: #1d355710;
}

.appContainer {
  height: fit-content;
  width: 100vw;
  left: 0;
  top: 0;
  overflow-y: scroll;
  background-color: var(--color-background);
}

.pageContainer {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 4rem;
  overflow-y: scroll;
}

.primaryButton {
  font-size: 16px;
  background-color: var(--color-primary);
  color: white;
  border-radius: 16px;
  padding: 8px;
  width: 100%;
}

.secondaryButton {
  font-size: 16px;
  background-color: transparent;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 16px;
  padding: 8px;
  width: 100%;
}

.modalContainer {
  background-color: white;
  width: 90vw;
  border-radius: 8px;
  box-shadow: 0 0 5px var(--color-background);
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent black background */
  backdrop-filter: blur(0.25px); /* Apply a blur effect to the background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalCompleteContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
  width: 80%;
  margin: auto;
}

.modalCompleteContent > input {
  padding: 8px;
  text-indent: 8px;
  border: none;
  width: 80%;
  border: 1px solid var(--color-background);
  margin-top: 12px;
  margin-bottom: 12px;
  border-radius: 16px;
}

input {
  color: var(--color-primary);
}

input::placeholder {
  color: #1d355775;
}

input:focus {
  outline: none;
}

.modalCompleteContent > input:focus {
  outline: none;
}

.buttonSpan {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
}

.cardTitle {
  font-size: 16px;
  font-weight: bold;
  color: var(--color-primary);
  text-transform: uppercase;
  text-align: left !important;
  margin-bottom: 8px;
  margin-top: 8px;
}

.cardTitleHighlight {
  color: rgb(246, 193, 113);
  width: fit-content;
  text-wrap: nowrap;
  text-transform: lowercase;
}

.closeOverlay {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 16px;
  padding-top: 16px;
}

.spinnerContainer {
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.spinner {
   width: 56px;
   height: 56px;
   --c: radial-gradient(farthest-side,#1D3554 92%,#0000);
   background: var(--c) 50% 0,
          var(--c) 50% 100%,
          var(--c) 100% 50%,
          var(--c) 0    50%;
   background-size: 13.4px 13.4px;
   background-repeat: no-repeat;
   animation: spinner-kh173p 1s infinite;
}

.spinner_small {
  width: 32px;  /* Reduced from 56px */
  height: 32px; /* Reduced from 56px */
  background-size: 8px 8px; /* Adjusted proportionally */
}

.spinner_white {
  width: 32px;  /* Reduced from 56px */
  height: 32px; /* Reduced from 56px */
  --c: radial-gradient(farthest-side, #ffffff 92%, #0000);
  background: var(--c) 50% 0,
         var(--c) 50% 100%,
         var(--c) 100% 50%,
         var(--c) 0    50%;
  background-size: 8px 8px; /* Adjusted proportionally */
  background-repeat: no-repeat;
  animation: spinner-kh173p 1s infinite;
}

button {
  text-transform: uppercase;
  font-size: 14px !important;
}



@keyframes spinner-kh173p {
   to {
      transform: rotate(.5turn);
   }
}
