.templateContainer {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
  gap: 16px;
  overflow-x: scroll;
  padding-bottom: 10rem;
}

.templateContainer > h1 {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--color-primary);
  text-indent: 8px;
}

.emptyTemplatePage {
  box-shadow: 0 0 5px var(--color-tertiary);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin: auto;
  margin-top: 0;
  width: 70vw;
  color: var(--color-tertiary);
  gap: 8px
}