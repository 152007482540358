.cartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: fit-content;
  padding-bottom: 10rem;
  overflow: scroll;
  padding-top: 16px;
  overflow-x: hidden;
  gap: 16px;
}

.emptyContent {
  box-shadow: 0 0 5px var(--color-tertiary);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin: auto;
  margin-top: 2rem;
  width: 70vw;
  color: var(--color-tertiary);
  gap: 8px;
}

.emptyCartButtonSpan {
  display: flex;
  justify-content: center;
  width: 70vw;
}

.cartCancelButton {
  color: var(--color-red);
  border: 1px solid var(--color-red);
  width: "100%";
  margin-top: 16px;
}

.empty_content > text {
  font-size: 16px;
  color: var(--color-tertiary);
  font-weight: 300;
}

.cartHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cartHeaderRow {
  width: 95vw;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 0 2px var(--color-tertiary);
  background-color: white;
  border-radius: 16px;
}

.cartHeaderText {
  font-size: 16px;
  color: var(--color-secondary);
  font-weight: 300;
}

.cartHeaderSpan {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.cartList {
  width: 90%;
  padding: 16px;
  box-shadow: 0 0 5px rgb(207, 207, 207);
  border-radius: 8px;
  background-color: white;
}

.cartRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 0.5px dotted var(--color-tertiary);
  padding-top: 4px;
  padding-bottom: 4px;
}

.cartItem {
  display: grid;
  flex: 3;
  grid-template-columns: 1fr 1fr 1fr 1fr 15fr;
  align-items: center;
  width: 100%;
  gap: 8px;
}

.cartItemText {
  font-size: 12px;
  color: var(--color-secondary);
  font-weight: 300;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cartItemPrice {
  font-size: 12px;
  color: var(--color-primary);
  font-weight: 400;
  text-transform: uppercase;
  font-weight: bold;
}

.cartItemCount {
  font-size: 12px;
  color: var(--color-primary);
}

.cartSavings {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.cartSavingsSpan {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  box-shadow: 0 0 2px var(--color-tertiary);
  width: 100%;
  padding: 8px;
  background-color: white;
  border-radius: 16px;
}

.cartSavingsText {
  font-size: 16px;
  color: var(--color-secondary);
  text-indent: 8px;
}

.cartEdit {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 4px;
  width: 100%;
}

.cartSavingsDropdown {
  background-color: white;
  width: 100%;
  border-radius: 0 0 16px 16px;
  border-top: 1px dotted var(--color-tertiary);
  display: flex;
  flex-direction: row;
}

.cartSavingsDropdownLeft {
  width: 50%;
  padding: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  border-right: 1px dotted var(--color-tertiary);
}

.cartSavingsDropdownRight {
  width: 50%;
  display: flex;
  padding: 16px;
  gap: 8px;
  flex-direction: column;
  align-items: start;
}

.cartSavingsSpanDropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
}

.cartSavingsTextPrice {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.cartSavingsListText {
  font-size: 12px;
  color: var(--color-secondary);
  font-weight: 300;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cartSavingsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 2px var(--color-tertiary);
  width: 100%;
  background-color: white;
  border-radius: 16px;
}

.cartButtonSpan {
  display: flex;
  width: 95vw;
  justify-content: space-between;
  gap: 8px;
}

.cartClear {
  background-color: transparent;
  color:var(--color-red) !important;
  border: 1px solid var(--color-red) !important;
}
