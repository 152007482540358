.shopOverlay {
    padding: 16px;
    padding-bottom: 24px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    gap: 16px
}

.shopModalContainer {
    
}

.shopOverlay > h1 {
    width: 100%;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    color: var(--color-primary);
}

.shopInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.shopInfoOption {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 16px;
    font-size: 16px;
    gap: 4px;
    width: 95%;
    box-shadow: 0 0 4px var(--color-tertiary);
}

.shopInfoOption > h4 {
    font-weight: bold;
    color: var(--color-secondary)
}

.shopInfoOption > p {
    color: var(--color-secondary)
}