.navContainer {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: start;
  width: 60%;
  height: 100vh;
  position: fixed;
  top: 0%;
  background-color: var(--color-primary);
  z-index: 1000;
}

.navLink {
  text-decoration: none;
  color: white;
  font-size: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  padding-left: 32px;
  padding-right: 24px;
}

.navUser {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 16px;
    position: relative;
    left: 4%;
    width: 96%;
    border-radius: 16px 0 0 16px;
    box-shadow: 0 0 10px var(--color-tertiary);
    border-right: none;
    padding: 16px;
    margin-bottom: 2rem;
}

.navUser > text {
    font-size: 16px;
    color: white;
}

.navLinkIcon {
    position: absolute;
    left: 32px;
}

.navClose {
  justify-content: right;
  position: relative;
  height: 4rem;
}

.navLink > text {
  font-size: 16px;
  color: white;
}

.selected {
  background-color: #FFFFFF25;
}

.topNavContainer {
  height: 4rem;
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: var(--color-primary);
  align-items: center;
  position: fixed;
  z-index: 999;
  color: white;
  font-size: 24px;
}

.topNavContainerCart {
  align-self: center;
}

.topNavContainerMenu {
  margin-left: 1rem;
  position: absolute;
  left: 0;
}

.navBackdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.cartSpan {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 3%;
  gap: 4px;
  height: 32px;
  width: 38px;
  /* background-color: red; */
}

.shopCartIcon {
 width: 100%;
 display: flex;
 justify-content: flex-end;
 padding-right: 24px;
}