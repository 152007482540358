.templateCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 16px;
  width: 100%;
  padding: 16px;
  background-color: white;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  gap: 8px !important;
}

.templateTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--color-primary);
}

.templateTitle > h4 {
  font-size: 16px;
  font-weight: bold;
}

.templateCardContainer > h5 {
  font-size: 12px;
  font-weight: bold;
  color:var(--color-tertiary);
}

.templateItems {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.templateItem {
  font-size: 14px;
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  width: 100%;
}
.templateRule {
  border-top: 1px dotted var(--color-tertiary);
  height: 0.5px;
  width: 100%;
  align-self: center;
}

.templateItemHeadSpecific {
  font-size: 14px;
  width: 100%;
  font-weight: bold;
  color: var(--color-primary);
}

.templateItemSpecific{
  font-size: 14px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.templateItemHead > p {
  color: var(--color-tertiary);
  font-weight: bold;
  font-size: 12px;
}

.templateItem > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.templateItem p:last-child {
  text-align: right;
}

.templateItem p:nth-child(2) {
  text-align: center;
}
