.dashboardContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
  overflow: scroll;
}

.dashboardTile {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  height: 10vh;
  width: 45vw;
  background-color: white;
  color: var(--color-primary);
}

.tileComingSoon {
  background-color: rgba(255, 255, 255, 0.196);
}

.savingsGrid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch; /* Stretch the columns to the full width */
  padding-left: 16px;
  padding-right: 16px;
}

.savingsRow {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal columns */
  gap: 16px; /* Space between columns */
  padding: 12px 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  width: 100%; /* Ensures it takes full width */
}

.rowDate, .rowItems, .rowSavings {
  text-align: left; /* Ensures text is left-aligned */
  display: flex;
  justify-content: center; /* Center-align the content within the columns */
  align-items: flex-start; /* Align items to the top within the column */
}

.rowItemsHead, .rowSavingsHead {
  text-align: center; /* Ensures text is left-aligned */
  font-weight: bold;
}

.rowDateHead {
  margin-left: 12px;
}

.rowDate {
  color: var(--color-seconday);
}

.rowItems {
  color: var(--color-primary);
}

.rowSavings {
  font-weight: bold;
  color: var(--color-green);
}

.tile > h5 {
  font-weight: bold;
}

.tileGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 1rem;
  padding: 16px;
  padding-top: 0px;
}

.welcomeSpan {
  padding: 16px;
  padding-left: 24px;
  padding-right: 24px;
}

.welcome {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--color-primary);
}

.welcomeHand {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 1rem;
  align-items: center;
}

.emptyShop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-tertiary);
  border-radius: 16px;
  width: 90vw;
  padding: 16px;
  align-self: center;
  gap: 8px
}
  
.emptyShop > h4 {
  color: var(--color-tertiary)
}