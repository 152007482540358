.searchContainer {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 160px;
  gap: 16px;
  position: relative;
}

.searchInputContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding-top: 0;
}

.shopText {
  color: var(--color-secondary);
  font-size: 16px;
}

.searchInputContainer > span > input {
  border: none;
  padding: 8px;
  border-radius: 16px;
  border: 1px solid var(--color-primary);
  font-size: 16px;
  color: var(--color-primary);
}

.storeSelect {
  color: var(--color-primary);
  font-size: 16px;
  font-weight: 100;
  /* text-indent: 16px; */
}

.searchInputContainer > span > input::placeholder,
.searchInputContainer > span > input {
  color: var(--color-primary);
  font-size: 16px;
  font-weight: 100;
  /* text-indent: 8px; */
}

.searchInputContainer > input:focus,
.searchInputContainer > span > input:focus {
  outline: none;
}

.storeDropdown {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 80%;
}

.textContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  margin-bottom: 8px;
  /* border-bottom: 1px solid var(--color-primary) */
}

.storeContentContainer {
  box-shadow: 0 0 5px rgb(207, 207, 207);
  border-radius: 16px;
  padding: 16px;
  width: 90%;
  background-color: white;
}

.shopResultList {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 16px;
  overflow-y: scroll;
}

.bp5-control.bp5-checkbox {
  color: var(--color-primary);
  font-size: 16px;
  font-weight: 300;
}

.cardSpinner {
  color: var(--color-primary);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.storeSearchButton {
  padding: 8px;
  font-size: 16px;
  border-radius: 16px;
  background-color: var(--color-primary);
  color: white;
  width: 100%;
  height: 40px;
}

.storeClearButton {
  padding: 8px;
  font-size: 16px;
  border-radius: 16px;
  border: 1px solid var(--color-primary);
  background-color: transparent;
  color: var(--color-primary);
  width: 100%;
  height: 40px;
}

.storeSearchButtonDisable {
  padding: 8px;
  font-size: 16px;
  border-radius: 16px;
  background-color: var(--color-primary);
  color: white;
  width: 100%;
  height: 40px;
}

.searchButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.storeSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.bp5-control.bp5-checkbox > input:checked {
  color: red;
  outline: blue;
}

.volumeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-background);
  border-radius: 16px;
  width: 100%;
  padding-left: 8px;
  padding-right: 16px;
  height: 42px;
}

.unitMenu {
  min-width: 0% !important;
  max-width: 200% !important;
  width: 120%;
}

.volumeContainerText {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 90%;
}

.volumeMinMax {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 40%;
}

.volumeMinMax > text,
.volumeContainerText > text {
  font-size: 16px;
  color: var(--color-primary);
}

.volumeMinMax > input {
  background-color: transparent;
  padding: 8px;
  width: 50%;
  text-align: center;
}

.volumeMinMax > input:focus {
  outline: none;
}

.customSlider {
  z-index: 1;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-left: 16px !important;
  padding-right: 16px !important;
  height: 42px;
  width: 100%;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 8px;
  justify-content: center;
}

.shopCheckboxContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  width: 100%;
  padding-left: 8px;
}

.shopCheckbox {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 8px
}

.storeArrow {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1D355430;
  border-radius: 16px;
  position: fixed;
  top: 92%;
  right: 2.5%;
}