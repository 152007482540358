.cardContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid var(--color-background);
  box-shadow: 0 0 2px var(--color-tertiary);
}

.cardContainerTop {
  padding: 24px;
  padding-bottom: 16px;
  padding-top: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}

.cardContainerStoreChips {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cardContainerMiddle {
  display: flex;
  flex-direction: row;
}

.cardContainerLeft {
  flex: 5;
  display: flex;
  max-width: 70%;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.cardContainerRight {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: start;
  gap: 16px;
  position: relative;
}

.cardContainerRight > img {
  margin: auto;
}

.cardTitle {
  font-size: 16px;
  font-weight: bold;
  color: var(--color-pimary);
  text-transform: uppercase;
  text-align: left !important;
  margin-bottom: 8px;
  margin-top: 8px;
}

.cardPrice {
  font-size: 16px;
  color: var(--color-pimary);
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  align-items: center;
}

.cardVolume {
  font-size: 12px;
  font-weight: 300;
  color: var(--color-secondary);
  margin-bottom: 8px;
}

.cardBrand {
  font-size: 12px;
  font-weight: 300;
  color: var(--color-tertiary);
  margin-bottom: 8px;
}

.cardUnitInput {
  width: 20%;
}

.cardCountContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  justify-content: space-evenly;
  height: 32px;
  width: 25%;
}

.unitIcon {
  background-color: var(--color-primary);
  padding: 8px;
  border-radius: 8px;
}
.cardUnitCount {
  padding-left: 8px;
  padding-right: 8px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
}

.cardContainerBottom {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 8px;
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--color-tertiary);
  border-radius: 0 0 16px 16px;
}

.cardPriceChip {
  font-size: 8px;
  align-self: center;
}

.cardChips {
  display: flex;
  flex-direction: row;
  justify-content: right;
  gap: 8px;
  width: 100%;
}

.loginAlert {
  width: 60%;
}

.cardOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #1D355725;
  z-index: 100;
  border-radius: 16px;
}

.cardValue {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 8px;
}

.productCardRemoveButton {
  width: 40% !important;
  background-color: var(--color-red) !important;
  border: 1px solid transparent;
  z-index: 101;
}

.productCardAddButton {
  width: 40% !important;
  border: 1px solid transparent;
}

.vitalityImage {
  width: 25px;
  position: absolute;
  right: 0;
  top: 0
}