.basketOverlayContent {
    padding: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.basketOverlayProduct {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-bottom: 8px;
}

.basketOverlayProductRight {
    flex: 2;
    height: 100%;
}

.basketOverlayProductLeft {
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    padding-right: 8px;
    height: 100%;
}

.basketOverlayButtons {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 8px;
    width: 100%;
    padding-top: 8px;
}

.basketOverlayFindButton {
    width: 40%;
    height: 32px;
}

.basketOverlayApplyButton {
    background-color:  var(--color-green) !important
}

.basketOverlayRemoveButton {
    background-color:  var(--color-red) !important;
}

.basketOverlayContentHeader{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}

.editButton {
    background-color: white;
    border-radius: 8px;
    padding: 8px;
}