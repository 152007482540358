.feedbackPage {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100vh;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.feedbackContainer {
    width: 80%;
    height: 35vh;
    box-shadow: 0 0 4px var(--color-background);
    background-color: white;
    border-radius: 8px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 16px;
}

.feedbackHeader > h4 {
    font-size: 16px;
    color: var(--color-primary);
    font-weight: bold;
}

.feedbackHeader > h6 {
    font-size: 14px;
    color: var(--color-secondary);
}

.feedbackButton {
    width: 80%;
    border: 1px solid transparent;
}

.feedbackImage {
    width: 400px;
    position: absolute;
    top: 10vh
}

.feedbackInput {
    width: 90%;
    background-color: var(--color-background);
    padding: 16px;
    border-radius: 8px;
    color: var(--color-primary);
    /* margin-top: 12px; */
    font-size: 14px;
}

.feedbackInput::placeholder {
    color: var(--color-tertiary)
}

.feedbackInput:focus {
    outline: none;
}

.feedbackHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px
}

.invalid {
    background-color: transparent;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
}