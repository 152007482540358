.templateGrid {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.templateRow {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 16px;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  box-shadow: 0 0 4px var(--color-tertiary);
  border-radius: 16px;
  white-space: nowrap;
  overflow: hidden;
}

.templateSelectOverlay {
  padding: 16px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.emptyTemplateOverlay {
  gap: 4px
}

.templateSelectOverlay > h1 {
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: var(--color-primary);
}

.templateName {
  display: flex;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  align-items: center;
  justify-content: left;
  gap: 8px;
  color: var(--color-primary);
}

.templateName > p {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  width: 80%;
}

.emptyTemplate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  width: 80vw;
  padding: 16px;
  align-self: center;
  gap: 8px;
}

.emptyTemplate > h4 {
  color: var(--color-tertiary);
}

.templateShoppingContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  /* padding-top: 32px; */
  gap: 16px
}

.templateShoppingContainer > h4 {
  color: var(--color-primary);
  font-weight: bold;
}
