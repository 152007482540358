.customCheckbox {
  display: flex;
  justify-content: left;
  gap: 8px;
  align-items: center;
}

.customBox {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid var(--color-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
}
