.editNameSpan {
  display: flex;
  justify-content: left;
  gap: 8px;
  align-items: center;
  width: 100%;
}

.editNameHeading {
  font-weight: bold;
  color: var(--color-primary);
  font-size: 16px;
}
