.input,
.inputEmpty {
  border-radius: 16px 0px 0px 16px;
  background-color: var(--color-background);
  text-indent: 8px;
  width: 90%; /* Adjust as needed */
  padding: 8px;
}

.inputFocus {
  border-radius: 16px 0px 0px 0px;
  text-indent: 8px;
  width: 90%; /* Adjust as needed */
  padding: 8px;
  outline: none;
  background-color: white;
  /* background-color: var(--color-background); */
}

.inputSelect {
  border-radius: 16px 0px 0px 16px;
  /* background-color: var(--color-background); */
  caret-color: white;
  text-indent: 8px;
  width: 100%; /* Adjust as needed */
  padding: 8px;
}

.input:focus,
.inputSelect:focus {
  outline: none;
  text-indent: 8px;
  border-radius: 16px 0px 0px 0px;
  color: var(--color-tertiary);
  background-color: white;
}

.inputEmpty:focus {
  outline: none;
  text-indent: 8px;
  border-radius: 16px 0px 0px 0px;
  color: var(--color-tertiary);
  background-color: white;
}

/* .input::placeholder,
.inputEmpty::placeholder,
.inputSelect::placeholder {
  color: var(--color-tertiary);
  font-size: 16px;
  text-indent: 8px;
  font-weight: 300;
}

.input:focus::placeholder,
.inputEmpty:focus::placeholder,
.inputSelect:focus::placeholder {
  color: var(--color-background);
} */

.menuList {
  overflow-y: auto;
  position: absolute;
  top: 95%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 0 0 16px 16px;
  background-color: white;
  z-index: 2;
  /* border: 1px solid var(--color-primary); */
  border-top: 1px solid transparent;
}

.menuItem {
  padding: 8px;
  text-indent: 8px;
  cursor: pointer;
  background-color:white;
  /* margin-bottom: 2px; */
  border-bottom: 1px solid var(--color-background);
  border: 1px dotted var(--color-tertiary);
  border-top: 1px solid transparent;
}

.menuItemSelected {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right:12px;
  text-indent: 8px;
  cursor: pointer;
  background-color: #4075ba40;
  border: 1px solid transparent;
  border-bottom: 1px solid white;
}

.selectorContainer {
  position: relative;
  display: inline-block;
  width: 100%;
}

.nameSearchItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
}

.nameSearchItem > p {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
  overflow-x: hidden;
}

.nameSearchImgTitle {
  display: flex;
  gap: 16px;
}

.inputClearSpan {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid transparent;
}

.inputClearSpanFocus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px dotted var(--color-tertiary);
  border-radius: 16px 16px 0px 0px;
}

.inputClear {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background);
  border-radius: 0px 16px 16px 0px;
  width: 10%;
}

.inputClearFocus {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 16px 0px 0px;
  width: 10%;
}



.nameSearchItem > img {
  width: 10px;
  height: 10px !important;
}
